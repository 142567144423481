import * as React from "react"
import backpapier from '../images/backpapier.png'

// styles
const pageStyles = {
  color: "#232129",
  padding: "1em",
  fontFamily: "Raleway, sans-serif",
  fontSize: "0.9rem",
}
const headingStyles = {
  marginTop: 0,
  maxWidth: "320px",
  fontFamily: "Raleway, sans-serif",
}

const paragraphStyles = {
  marginBottom: "20px",
}

const Recipe = () => {
  return (
    <main style={pageStyles}>
      <title>Mr and Mrs Dandyman</title>
      <h1 style={headingStyles}>Hausbrot Rezept</h1>
      <h2 style={headingStyles}>Du brauchst:</h2>
      <p style={paragraphStyles}>
        <ul>
          <li>Eine große Schüssel</li>
          <li>Eine Auflaufform (am besten mit Deckel)</li>
          <li>Einen Gusseisentopf</li>
          <li>Backpapier</li>
          <li>Eine Waage</li>
          <li>Ein Gärkörbchen oder eine Schüssel (ca. 26cm)</li>
          <li>Eine Teigkarte</li>
          <li>Ein sehr scharfes Messer/Rasierklinge</li>
          <li>Einen aktiven Sauerteig</li>
        </ul>
      </p>
      <h2 style={headingStyles}>Zutaten:</h2>
      <p style={paragraphStyles}>
        <ul>
          <li>244g Wasser</li>
          <li>70g aktiven Sauerteig</li>
          <li>280g Weizenmehl (<a href ="https://bongu.de/#/Artikel/9/t80-label-rouge">T80 Rouge</a> bzw. <a href="https://krusteundkrume.at/shop/weizenmehl-w1600-forstner-muehle/">W1600</a>, oder als Alternative das W700 aus dem Supermarkt)</li>
          <li>70g Dinkel-Vollkornmehl (<a href="https://bongu.de/#/Artikel/9/ur-dinkel-vollkornmehl">Ur-Dinkel Vollkorn</a>, bzw. normales Dinkel Vollkornmehl aus dem Supermarkt)</li>
          <li>7g Salz</li>
        </ul>
      </p>
      <h2 style={headingStyles}>Zubereitung:</h2>
      <h3 style={headingStyles}>Aktiver Sauerteig:</h3>
      <p style={paragraphStyles}>
        Um genügend Sauerteig für dein Brot zu bekommen, musst du den bereits aktivierten Sauerteig im richtigen Verhältnis füttern.<br />
        Dazu nimmst du z.B.: 20g Sauerteig und vermengst diesen mit 40g Weizenmehl und 40g Wasser.<br />
        Dieses Gemisch lässt du dann für 4-6 Stunden an einem warmen Ort stehen.<br />
        Je nachdem wie warm es ist, sollte sich das Volumen in dieser Zeit verdoppeln. Ein Trick um herauszufinden, ob der Sauerteig genug Gase gebildet hat, ist es einen kleinen Löffel zu nehmen und einen Teil des Sauerteigs ins Wasser fallen zu lassen. Schwimmt der Teig, ist er fertig zum Backen.<br />
      </p>
      <h3 style={headingStyles}>Autolyse:</h3>
      <p style={paragraphStyles}>
        Ist genügend aktiver Sauerteig vorhanden, kannst du mit dem Hauptteig beginnen. <br/>
        Vermische dazu das Wasser und Mehl in einer großen Schüssel, schabe die Ränder mit der Teigspachtel ab und lass die Masse für 30 bis 40 Minuten stehen.
        Dadurch verbinden sich die ersten Glutenstränge und das Kneten wird um einiges erleichtert.
      </p>
      <h3 style={headingStyles}>Sauerteig und Salz dazu mischen:</h3>
      <p style={paragraphStyles}>
        Leere den aktiven Sauerteig über das Wasser/Mehl Gemisch, verteile alles gleichmäßig, drücke ihn mit nassen Fingern in den Teig und mische alles anschließen gut durch.<br/>
        Dazu kannst du die Ränder des Teiges immer wieder nach innen falten, wie zum Beispiel am Anfang dieses <a href="https://www.youtube.com/watch?v=gtRPUK6fQzY&ab_channel=VegPatchKitchenCookerySchool">Videos </a>. <br/>
        Lass den Teig wieder 10 Minuten stehen, bevor du das Salz hinzufügst. <br/>
        Dazu leere das Salz gleichmäßig über den Teig und drücke/falte es wie eben beschrieben in das Gemisch. <br/>
        <strong>Stelle jetzt den Timer auf 4 1/2 Stunden</strong>. <br/>
      </p>
      <h3 style={headingStyles}>Kneten:</h3>
      <p style={paragraphStyles}>
        Nachdem das Salz gut vermengt ist, kannst du anfangen den Teig zu kneten. <br/>
        Falls du eine Küchenmaschiene mit Teighaken besitzt, lass den Teig 5 Minuten auf langsamer Stufe laufen. <br />
        Anschließend 5 Minuten ruhen und danach nochmal 5 Minuten auf schneller Stufe laufen. <br/>
        Falls du keine Küchenmaschiene besitzt, rate ich dir den Teig mittels <a href="https://www.youtube.com/watch?v=9LXcchONCwg&ab_channel=RussellCohen">Slap and Fold</a> zu kneten. <br/>
        Dazu würde ich ebenfalls 5 Minuten kneten (Hände feucht machen nicht vergessen), 5 Minuten ruhen, 5 Minuten kneten, 5 Minuten ruhen und ein letztes Mal 5 Minuten kneten.<br/>
        Decke den Teig in jeder Ruhepause mit der Schüssel zu, um austrocknen zu verhindern.<br/>
        Wenn der Teig schön geschmeidig ist, kannst du diesen in die Auflaufform legen und zudecken. <br/> <strong>Stelle einen weiteren Timer auf 40 Minuten.</strong>
      </p>
      <h3 style={headingStyles}>Coil Fold:</h3>
      <p style={paragraphStyles}>
        Nachdem der Teig 40 Minuten ruhen konnte, sollte man den Teig falten um diesen in Form zu halten bzw. um dessen Struktur zu stärken. <br/>
        Ein Video dazu gibt es <a href="https://www.youtube.com/watch?v=gD91010wfgA&ab_channel=CookieundCo">hier</a>.
        Das Ganze wiederholst du alle 40 Minuten noch 3 mal. <br/>
        Danach kannst du den Teig bis zum Ende des ersten Timers ruhen lassen. <br/>
      </p>
      <h3 style={headingStyles}>Teig in Form bringen:</h3>
      <p style={paragraphStyles}>
        Um den Teig in die richtige Form zu bringen, bzw. um etwas Spannung aufzubauen, muss dieser zuerst vorgeformt werden. <br/>
        Dazu bestäubst du die Arbeitsfläche mit etwas Mehl und kippst den Teig mit der Oberseite nach unten auf die bemehlte Fläche. <br/>
        Danach faltest du den Teig mit Hilfe einer Teigspachtel von jeder Seite in die Mitte und wendest ihn, sodass die Oberfläche wieder nach oben schaust. <br />
        Anschließend ziehst du den Teig mit den Händen (mit den kleinen Fingern) von jeder Seite zu dir um eine Kugel zu bilden, bestäubst diese mit Mehl und lasst sie 20-30 Minuten ruhen. <br />
        Am besten schaust du dir dieses <a href="https://www.youtube.com/watch?v=Op-LKk-i4zQ&ab_channel=ThePerfectLoaf">Video</a> an um den Ablauf zu verstehen :). <br/>
        Nach der angegebenen Pause, muss der Teig in die finale Form gebracht werden.
        Hierzu habe ich zwei Videos <a href="https://www.youtube.com/watch?v=h7eqwYLv2tI&ab_channel=TheBreadCode">hier</a> und <a href="https://www.youtube.com/watch?v=IWA0RAAsBHg&ab_channel=ThePerfectLoaf">hier</a>.<br/>
        Anschließend streue etwas Mehl (am besten Reismehl) in den Gärkorb (anfangs würde ich nicht an Mehl sparen) und lege die Teigkugel kopfüber hinein. <br />
        Falls du kein Gärkörbchen besitzt, kannst du als Alternative ein Geschirrtuch in eine Schüssel geben und dieses bestäuben.<br/>
        Nachdem der Teig sicher im Gärkörbchen gelandet ist, muss dieser über Nacht in den Kühlschrank für die Übernachtgare. <br/>
        Um das Austrocknen des Teiges zu verhindern, sollte dieser zugedeckt werden (z.B.: mit einer Folie, einem feuchten Geschirrtuch oder einer Badehaube :)).<br/>
      </p>
      <h3 style={headingStyles}>Backtag:</h3>
      <p style={paragraphStyles}>
        Bevor du den Teig aus dem Kühlschrank holst, sollte der Gusseisentopf samt Deckel im Backrohr bei 250°C für ca. 40 Minuten vorgeheizt werden. <br/>
        Da der Topf danach sehr heiß ist und es schwierig ist, das Brot darin zu platzieren, schneide ich mir aus Backpapier eine runde Form mit zwei längeren Enden/Laschen. <br/>
        Das ganz sieht ca. so aus: <br />
        <img width="200px" src={backpapier}/> <br/>
        Nachdem der Topf aufgeheizt ist, ist es Zeit das Brot aus dem Kühlschrank zu nehmen und es auf dem Backpapier zu platzieren. <br/>
        Schneide nun das Brot mehrmals ein, hole den Topf aus dem Ofen und lege es mit Hilfe der Laschen hinein. <br/>
        Stelle den geschlossenen Topf wieder in den Ofen, reduziere die Temperatur auf 240°C und stelle den Timer auf 20 Minuten. <br/>
        Nachdem der Timer abgelaufen ist, entferne den Deckel, reduziere die Temperatur auf 230°C und stelle den Timer erneut auf 20 Minuten bzw. backe das Brot bis die gewünschte Bräune erreicht ist. <br/>
        Hole das Brot nun aus dem Topf und lasse es 1 Stunde auskühlen. <br/>
        Fertig :) <br/>
      </p>
    </main>
  )
}

export default Recipe
